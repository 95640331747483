import { Link } from "gatsby"
import React from "react"
import "./header.css";
import logo from "../images/logo-white.png"

const Header = () => (
  <header>
    <Link to="/">
      <img src={logo} className="navbar-logo" alt="logo" />
    </Link>
  </header>
)

export default Header

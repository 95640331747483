import React from "react";

import { faLinkedinIn, faInstagram, faFacebookF } from '@fortawesome/fontawesome-free-brands';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./footer.css";

const Footer = () => (
  <footer>
    <div className="footer-container">
      <div className="footer-content">
        <p>Connect with me</p>
        <div>
          <a className="footer-icon" href="https://www.linkedin.com/in/nerinaserra" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a className="footer-icon" href="https://www.facebook.com/nserra.ns" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a className="footer-icon" href="https://www.instagram.com/nserra.__" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a className="footer-icon" href="mailto:nerina.serra@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
